interface IMenuItem {
  name: string;
  href?: string;
  routerLink?: string;
  queryParams?: boolean;
  className?: string;
  children?: IMenuItem[];
}

export const menuItems: IMenuItem[] = [
  {
    name: 'Инструменты',
    children: [
      {
        name: 'Биддер',
        routerLink: '/tools/bidder'
      },
      {
        name: 'Автоответы',
        routerLink: '/tools/autoreviews'
      },
      {
        name: 'Репрайсер',
        routerLink: '/tools/repricer'
      },
      {
        name: 'Калькулятор',
        routerLink: '/tools/calculator'
      }
    ]
  },
  {
    name: 'Мероприятия',
    href: 'https://club.marketguru.io/information?utm_medium=button&utm_campaign=mg.io&utm_content=header'
  },
  {
    name: 'Партнерам',
    href: 'https://partners.marketguru.io/'
  },
  {
    name: 'Тарифы',
    routerLink: '/prices',
    queryParams: true
  },
  {
    name: 'Услуги',
    children: [
      {
        name: 'Сопровождение эксперта',
        href: 'https://promo.marketguru.io/mashtabirovanie'
      },
      {
        name: 'Заказать SEO',
        href: 'https://academy.marketguru.io/seo_offer?utm_source=marketguru&utm_medium=soft'
      }
    ]
  },
  {
    name: 'Блог',
    href: 'https://marketguru.io/secrets/'
  },
  {
    name: 'Контакты',
    routerLink: '/contacts'
  },
  {
    name: 'База знаний',
    routerLink: '/docs'
  }
];
